.btn {
    width: 100px;
    background-color: #1677ff;
    padding: 4px 8px;
    color: #fff;
    letter-spacing: 8px;
    text-indent: 8px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.btn:hover {
    background-color: #4096ff;
}

.loading {
    pointer-events: none;
    background-color: #4096ff;
}

.circle {
    width: 12px;
    height: 12px;
    content: "";
    border: 2px solid;
    border-top-color: transparent;
    border-radius: 100%;
    animation: circle infinite .75s linear;
}

@keyframes circle {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}