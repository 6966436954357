.container {
    padding-bottom: 60px;
    position: relative;
    min-height: calc(100vh - 12px);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search {
    height: 85px;
    width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
        height: 40px;
        line-height: 40px;
        outline: none;
        border: 0;
        padding: 0 16px;
        color: #606266;
        width: 100%;
        border: 1px solid #DCDFE6;
        border-radius: 2px 0 0 2px;
    }

    input:focus {
        border-color: #0061c3 !important;
    }

    input:hover {
        border-color: #C0C4CC;
    }

    .search-btn {
        font-size: 14px;
        height: 40px !important;
        width: 120px !important;
        border: 1px solid #0061c3;
        text-indent: 8px;
        border-radius: 0 2px 2px 0 !important;
    }
}

.message {
    font-size: 14px;
    color: #FF6666;
    line-height: 1.5;
    margin-bottom: 20px;
}

.result {
    max-width: 900px;
    width: 100%;

    .label {
        width: 100%;
        height: 38px;
        background-color: #eaeaea;
        color: #0061c3;
        display: flex;
        align-items: center;
        padding: 8px 16px;
    }

    .body {
        width: 100%;
        padding: 16px;
        border: 1px solid #eaeaea;

        table {
            width: 100%;
            border-left: 1px solid rgba($color: #606266, $alpha: .6);
            border-top: 1px solid rgba($color: #606266, $alpha: .6);

            tr {
                color: #606266;
                font-size: 14px;
                line-height: 2;
            }

            tr:nth-child(odd) {
                background-color: #f8f8f8;
            }

            tr:hover {
                background-color: #F5F7FA;
            }

            td {
                border-right: 1px solid rgba($color: #606266, $alpha: .6);
                border-bottom: 1px solid rgba($color: #606266, $alpha: .6);
                padding: 4px 24px;
            }

            .name {
                text-align: right;
                font-weight: bold;
            }

            .value {
                text-align: left;
            }
        }
    }
}

.result:hover {
    transition-duration: .3s;
    box-shadow: 0 0 8px #eaeaea;
}

.notice {
    max-width: 900px;
    width: 100%;
    text-align: left;
    margin-top: 24px;
    line-height: 1.5;
    font-size: 14px;

    .emphasis {
        color: #FF6666;
        font-weight: bold;
    }
}