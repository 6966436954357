@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');


.content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    h1,
    h2 {
        font-family: Space Mono, monospace;
        line-height: 49px;
    }

    h1 {
        font-size: 24px;
        font-weight: 500;
        border-right: 1px solid rgba($color: #000000, $alpha: .3);
        padding-right: 23px;
        margin-right: 20px;
    }

    h2 {
        font-size: 14px;
        font-weight: normal;
    }
}