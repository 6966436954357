.footer {
    padding-top: 12px;
    height: 60px;
    position: absolute;
    width: 100%;
    bottom: 0;

    .divider {
        height: 2px;
        width: 100%;
        border-top: 2px solid #0061c3;
    }

    .content {
        display: flex;
        justify-content: center;
        height: calc(100% - 2px);
        align-items: center;
        column-gap: 32px;

        .icp {
            font-size: 13px;
            color: #707070;
            line-height: 24px;
            text-align: center;
            text-decoration: none;
        }
    }
}