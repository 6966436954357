.container {
    display: flex;
    justify-content: center;
}

.content {
    height: 300px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.upload-box {
    height: 200px;
    width: 400px;
    border: 2px dashed #cccccc;
    background-color: #fafafa;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #cccccc;

    .icon {
        width: 48px;
        height: 48px;
        fill: #cccccc;
    }
}

.filename {
    position: relative;
    line-height: 1.5;

    .remove-btn {
        position: absolute;
        right: 0;
        top: 4px;
        cursor: pointer;

        .delete-icon {
            width: 18px;
            height: 18px;

            path {
                fill: #FF6666;
            }
        }
    }
}

.message {
    font-size: 14px;
    line-height: 1.5;
    color: #FF6666;
}

.success {
    font-size: 14px;
    line-height: 1.5;
    color: #99CC33;
}

.upload-box:hover {
    border-color: #4096ff;
}

.upload-btn {
    padding: 8px;
    border-radius: 4px !important;
    width: 100% !important;
}