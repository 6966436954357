.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    border-radius: 4px;
    margin-top: 200px;
    box-shadow: 0px 2px 8px #ccc;
    display: flex;
    width: 400px;
    flex-direction: column;
}

.header {
    padding: 16px;

    .title {
        text-align: center;
        font-size: 18px;
        letter-spacing: 16px;
        text-indent: 16px;
        font-weight: 600;
    }
}

.formdata {
    height: 200px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    input {
        width: 100%;
        margin-top: 4px;
        line-height: 1.5;
        outline: none;
        border: none;
        border-bottom: 1px solid #cccccc;
        padding: 4px 8px;
    }

    input.warn::placeholder {
        color: #FF6666;
    }

    input:focus {
        border-color: #99CCFF;
    }

    .message {
        font-size: 14px;
        line-height: 1.5;
        color: #FF6666;
        padding-left: 2px;
    }

    .login-btn {
        font-weight: bold;
        padding: 8px;
        font-size: 14px;
        width: 100% !important;
    }
}