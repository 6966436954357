.header {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        margin-left: 20px;
        font-size: 32px;
        font-weight: bold;
        color: #0061c3;
        letter-spacing: 2px;
    }
}

.divider {
    height: 5px;
    width: 100%;
    border-top: 1px solid #0061c3;
    background-image: url('../assets/divider-bg.png');
}